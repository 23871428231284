<template>
  <div class="kw-tabs-wrapper-content">
    <h3>{{ $t("COMMON.PAYMENT") }}</h3>
    <div class="customer-payment-infos">
      <div class="customer-payment-infos-list">
        <ul>
          <li>{{ $t("CUSTOMERS.PAYMENT_METHOD") }}</li>
          <li>{{ $t("CUSTOMERS.CREDIT_CARD") }}</li>
        </ul>
        <ul>
          <li>{{ $t("COMMON.BILLING_ADDRESS") }}</li>
          <li>QC, Sainte-Rose-du-Nord G0V 1T0</li>
        </ul>
      </div>
      <div class="customer-payment-infos-card">
        <div class="customer-payment-infos-card-left">
          <div class="icon">
            <img
              src="../../../../../../public/img/kw-credit-card.svg"
              alt="icon"
            />
          </div>
          <div class="text">
            <ul>
              <li>**************8923</li>
              <li>Opal Simmon</li>
            </ul>
          </div>
        </div>
        <div class="customer-payment-infos-card-right">
          <el-button>
            {{ $t("COMMON.DELETE") }}
          </el-button>
          <img src="../../../../../../public/img/visa-logo.svg" alt="icon" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button } from "element-ui";
export default {
  name: "customer-view-payment",

  props: ["payment"],

  components: {
    [Button.name]: Button,
  },

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {},
};
</script>
