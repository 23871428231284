<template>
  <div class="kw-tabs-wrapper-content">
    <h3>{{ $t("COMMON.GENERAL_INFORMATIONS") }}</h3>
    <div class="all-infos">
      <div class="all-infos-image">
        <img v-if="customer.avatar"
             :title="customer.firstname"
             :alt="customer.firstname"
             :src="customer.avatar"
             class="argon-image mb-2"/>
        <img v-else
            src="../../../../../../public/img/user-image.png"
            class="argon-image mb-2"/>
        <!-- <img
          v-else
          src="/img/kw-default-image.svg"
          class="argon-image default"
        /> -->
      </div>
      <div class="all-infos-text">
        <dl class="row">
          <dt>{{ $t("COMMON.LASTNAME") }}</dt>
          <dd>
            {{ customer.lastname }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.FIRSTNAME") }}</dt>
          <dd>
            {{ customer.firstname }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.EMAIL") }}</dt>
          <dd>
            {{ customer.email }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.PHONE") }}</dt>
          <dd>
            {{ customer.phone }}
          </dd>
        </dl>
        <dl class="row" v-if="customer.birthday?.length > 0">
          <dt>{{ $t("CUSTOMERS.BIRTHDAY") }}</dt>
          <dd>
            {{ $formatDate(customer.birthday, "LL") }}
          </dd>
        </dl>
        <dl class="row" v-if="customer.sex?.length > 0">
          <dt>{{ $t("CUSTOMERS.SEX") }}</dt>
          <dd>
            {{ $t(`COMMON.${customer.sex}`) }}
          </dd>
        </dl>
        <dl class="row" v-if="customer.nationality?.length > 0">
          <dt>{{ $t("CUSTOMERS.NATIONALITY") }}</dt>
          <dd>
            {{ customer.nationality }}
          </dd>
        </dl>
        <dl class="row" v-if="customer.country?.length > 0">
          <dt>{{ $t("COMMON.COUNTRY") }}</dt>
          <dd>
            {{ customer.country }}
          </dd>
        </dl>
        <dl class="row" v-if="customer.region?.length > 0">
          <dt>{{ $t("COMMON.STATE") }}</dt>
          <dd>
            {{ customer.region }}
          </dd>
        </dl>
        <dl class="row" v-if="customer.city?.length > 0">
          <dt>{{ $t("COMMON.CITY") }}</dt>
          <dd>
            {{ customer.city }}
          </dd>
        </dl>
        <dl class="row" v-if="customer.address?.length > 0">
          <dt>{{ $t("COMMON.ADDRESS") }}</dt>
          <dd>
            {{ customer.address }}
          </dd>
        </dl>
        <dl class="row" v-if="customer.zipcode?.length > 0">
          <dt>{{ $t("CUSTOMERS.ZIP_CODE") }}</dt>
          <dd>
            {{ customer.zipcode }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd>{{ $timeZoneDateFormat(customer.created_at) }}</dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd>
            {{ $timeZoneDateFormat(customer.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "customer-view-global",

  props: ["customer"],

  components: {},

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {
    customerUpdated() {
      this.$emit("customerUpdated", true);
    },
  },

  mounted() {},

  watch: {
    customer(customer) {},
  },
};
</script>
