<template>
  <div class="kw-tabs-wrapper-content">
    <h3>{{ $t("CUSTOMERS.HISTORY") }}</h3>
    <div class="page-wrapper-table">
      <div class="page-wrapper-table-header">
        <div class="page-wrapper-table-header-left">
          <base-input
            v-model="query"
            class="search"
            type="search"
            prepend-icon="far fa-search"
            :placeholder="$t('COMMON.SEARCH')"
            clearable
          />
        </div>
        <div class="page-wrapper-table-header-right">
          <el-select
            class="select-primary pagination-select"
            v-model="pagination.perPage"
            :placeholder="$t('COMMON.PER_PAGE')"
          >
            <el-option
              class="select-primary"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="page-wrapper-table-body">
        <div class="page-wrapper-table-body-inner">
          <el-table
            class="align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="histories"
            @sort-change="sortChange"
          >
            <div class="table-loading" slot="empty" v-if="loading">
              <img src="/img/loading.gif" />
            </div>
            <el-table-column
              :label="$t('COMMON.DATE')"
              prop="date"
              sortable="custom"
              min-width="180"
            />
            <el-table-column
              :label="$t('CUSTOMERS.RESERVATION_NUMBER')"
              prop="reservationNumber"
              sortable="custom"
              min-width="180"
            />
            <el-table-column
              :label="$t('COMMON.LOCATION')"
              prop="location"
              sortable="custom"
              min-width="240"
            />
            <el-table-column
              :label="$t('CUSTOMERS.STAY')"
              prop="stay"
              sortable="custom"
              min-width="100"
            />
            <el-table-column
              :label="$t('CUSTOMERS.PAYMENT_MODE')"
              prop="paymentMode"
              sortable="custom"
              min-width="180"
            />
            <el-table-column fixed="right" min-width="120">
              <div slot-scope="{ row }" class="table-actions">
                <a
                  type="text"
                  @click="viewLog(row)"
                  class="table-action button"
                  data-toggle="tooltip"
                  v-if="$currentUserCan($permissions.PERM_VIEW_LOGS)"
                >
                  {{ $t("COMMON.DETAILS") }}
                </a>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div slot="footer" class="page-wrapper-table-footer">
        <p class="card-category">
          {{
            $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}

          <span v-if="selectedRows.length">
            &nbsp; &nbsp;
            {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
          </span>
        </p>
        <base-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        />
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Select,
  Option,
  Button,
} from "element-ui";
import "flatpickr/dist/flatpickr.css";
import { BasePagination } from "@/components";

export default {
  name: "customer-view-history",

  props: [],

  components: {
    BasePagination,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "-created_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      histories: [
        {
          id: 1,
          date: "09 mai 2024 19:45",
          reservationNumber: "XX-RE-000000",
          location: "Roulotte Confort",
          stay: "02",
          paymentMode: "Paypal",
        },
        {
          id: 2,
          date: "09 mai 2024 19:45",
          reservationNumber: "XX-RE-000000",
          location: "Roulotte Confort",
          stay: "02",
          paymentMode: "Visa",
        },
        {
          id: 3,
          date: "09 mai 2024 19:45",
          reservationNumber: "XX-RE-000000",
          location: "Roulotte Confort",
          stay: "02",
          paymentMode: "Visa",
        },
        {
          id: 4,
          date: "09 mai 2024 19:45",
          reservationNumber: "XX-RE-000000",
          location: "Roulotte Confort",
          stay: "02",
          paymentMode: "Visa",
        },
      ],
      loading: true,
      dateRange: null,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  created() {},

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      /* try {
        await this.$store.dispatch("logs/list", params);
        this.logs = this.$store.getters["logs/list"];
        this.total = this.$store.getters["logs/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } */
    },

    viewHistory(history) {
      this.$router.push({
        name: "View Log",
        params: { id: history.id },
      });
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },

  mounted() {},

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
  },
};
</script>
